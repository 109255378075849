import moment from "moment";
export const dateConvert =  {
    method:{
        convertToDate(date){
            return moment(date).format('DD-MM-YYYY');
        },


        convertToDate2(date){
            return moment(date).format('YYYY-MM-DD');
        },
        getYesterdayYMD(){
            const today = new Date();
            return moment(today).add(-1, 'days').format('YYYY-MM-DD');
        },
        addDayToDateYMD(date,add){
            return moment(date).add(add, 'days').format('YYYY-MM-DD');
        },
        getFirsDateOfWeekYMD(){
            const now = moment();
            const monday = now.clone().weekday(1);

            return moment(monday).format('YYYY-MM-DD');
        },
        getLastWeekYMD(){
            return {
                start: moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
                finish: moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
            };
        },
        getFirstDayOfMonthYMD(){
            const today = new Date();
            return moment(today).format('YYYY-MM-01');
        },
        getLastMonthYMD(){
            return {
                start: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                finish: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
            };
        },


        convertToDateTime(date) {
            return moment(date).format('DD-MM-YYYY HH:mm:ss');
        },
        convertToTime(date) {
            return moment(date).format('HH:mm:ss');
        },
        convertToDateYM(date){
            const splDate =date.split("-");//.substring(0, 10)
            const newDate = splDate[1]+"-"+splDate[0]+"-"+splDate[2]
            return moment(newDate).format('YYYY-MM-DD');
        },
        flatpickerToDateYM(date){
            date = date.substring(0, 10);
            const splDate =date.split("-");//.substring(0, 10)
            const newDate = splDate[2]+"-"+splDate[1]+"-"+splDate[0]
            const date2 = new Date(newDate);
            return moment(date2).format('YYYY-MM-DD');
        },
        dateToflatpicker(date) {
            moment.locale('tr')
            return moment(date).format('DD-MM-YYYY (ddd)');
        },
        getTodayYMD(){
            const today = new Date();
            return moment(today).format('YYYY-MM-DD');
        },
        getTodayYM(){
            const today = new Date();
            return moment(today).format('YYYY-MM');
        },
        getTodayYMtr(date){
            moment.locale('tr')
            return moment(date).format('MMMM-YY');
        },
        getDayInMonth(date){
            return moment(date, "YYYY-MM").daysInMonth()
        },
        addMonthToDateYM(date,add){
            return moment(date).add(add, 'months').format('YYYY-MM');
        },
        addMonthToDateYMD(date,add){
            return moment(date).add(add, 'months').format('YYYY-MM-01');
        },
        addDayMonthToDateYMD(date,add,day){
            return moment(date).add(add, 'months').add(day, 'days').format('YYYY-MM-DD');
        },
    }
};

class Lib_DateConvert {

}

export default Lib_DateConvert;