
import {defineComponent, onMounted, ref, watch} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr";
import {dateConvert} from "@/core/appcore/libs/Lib_DateConvert";

export default defineComponent({
  name: "dropdown-2",
  components: {},
  setup(props, ctx) {

    var refRangeDates = ref([dateConvert.method.convertToDate2(getPreviousDays(new Date(), 0)), dateConvert.method.convertToDate2(new Date())]);

    watch(
        () => refRangeDates.value,
        () => {
          console.log('watchchanges refRangeDates: ' + refRangeDates.value)
          ctx.emit('srcDate', "custom"+"#"+refRangeDates.value);
          //
        }
    );

    onMounted (()=>{
      initDatePicker();
    });

    function initDatePicker() {
      let myConfigS = {
        "locale": Turkish,
        mode: "range",
        dateFormat: "Y-m-d",
        defaultDate: refRangeDates.value,
      };
      let tmpSrcFromDate = flatpickr("#txtDates", myConfigS as any);
    }

    function getPreviousDays(prmDate, prmPrevious) {
      prmDate.setDate(prmDate.getDate() + prmPrevious);
      return prmDate;
    }


    const setDate = (date) => {
      ctx.emit('srcDate', date);
    }
    const setDate2 = (date) => {
      ctx.emit('srcDate', date);
    }
    ctx.emit('srcDate', 'thismonth');
    return {
      setDate,setDate2,
      refRangeDates,

    };
  }

});
